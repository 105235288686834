// extracted by mini-css-extract-plugin
export var addressCard = "vacancy-card-module--addressCard--1fad7";
export var addressLine = "vacancy-card-module--addressLine--1b593";
export var button = "vacancy-card-module--button--6dd3a";
export var candidateButton = "vacancy-card-module--candidateButton--d0b58";
export var card = "vacancy-card-module--card--e11b6";
export var competencesWrapper = "vacancy-card-module--competencesWrapper--7ceeb";
export var confidential = "vacancy-card-module--confidential--628c7";
export var container = "vacancy-card-module--container--0a8e6";
export var content = "vacancy-card-module--content--ebec0";
export var dangerValue = "vacancy-card-module--dangerValue--3e375";
export var fixedLink = "vacancy-card-module--fixedLink--59518";
export var img = "vacancy-card-module--img--920e7";
export var inactive = "vacancy-card-module--inactive--6e447";
export var mapButton = "vacancy-card-module--mapButton--4ef3b";
export var normalValue = "vacancy-card-module--normalValue--903fc";
export var other = "vacancy-card-module--other--52766";
export var remuneration = "vacancy-card-module--remuneration--55b8e";
export var remunerationLine = "vacancy-card-module--remunerationLine--dba58";
export var scale = "vacancy-card-module--scale--15f5d";
export var separator = "vacancy-card-module--separator--d242c";
export var shared = "vacancy-card-module--shared--c6d54";
export var title = "vacancy-card-module--title--f9997";
export var topCard = "vacancy-card-module--topCard--48d64";
export var vacancy = "vacancy-card-module--vacancy--09120";
export var value = "vacancy-card-module--value--e460d";
export var workingDays = "vacancy-card-module--workingDays--738a7";
export var workplace = "vacancy-card-module--workplace--970e7";