import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import * as classes from './info-banner.module.scss';
import { HiChevronLeft } from '@react-icons/all-files/hi/HiChevronLeft';
import { HiChevronRight } from '@react-icons/all-files/hi/HiChevronRight';
import { FaCircle } from '@react-icons/all-files/fa/FaCircle';

interface InfoBannerProps {
  mobile?: boolean;
}

const InfoBanner = (props: InfoBannerProps) => {
  const { mobile } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const content = [
    'A partir da candidatura você recebe a primeira resposta da vaga em até <b>2 dias úteis</b>.',
    'Todas candidaturas são analisadas por nós!',
    'Todas os processos seletivos na Vendoor são feitos totalmente através da plataforma. <b>Candidate-se sempre por aqui</b>.',
  ];

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < content.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  if (mobile) {
    return (
      <div className={classes.mobileContainer}>
        <button
          onClick={handlePrevious}
          className={currentIndex > 0 ? 'opacity-100' : 'opacity-0'}
        >
          <HiChevronLeft size={32} color="#E1F317" />
        </button>

        <div className={classes.contentWrapper}>
          <p className={classes.text}>
            Quanto tempo demora um processo na vendoor?
          </p>
          <p
            className={classes.contentText}
            dangerouslySetInnerHTML={{ __html: content[currentIndex] }}
          />
          <div className="flex gap-5 mt-auto">
            {content.map((_, index) => (
              <FaCircle
                key={Math.random()}
                size={6}
                color={index === currentIndex ? '#E1F317' : '#fff'}
              />
            ))}
          </div>
        </div>

        <button
          onClick={handleNext}
          className={
            currentIndex < content.length - 1 ? 'opacity-100' : 'opacity-0'
          }
        >
          <HiChevronRight size={32} color="#E1F317" />{' '}
        </button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <StaticImage
          src="../../images/user.png"
          alt="Vendoor"
          className={classes.logo}
          width={154}
          height={151}
        />
        <p className={classes.text}>
          Quanto tempo demora um processo na vendoor?
        </p>
      </div>
      <div className={classes.content}>
        {content.map(item => (
          <p
            key={Math.random()}
            className={classes.item}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))}
      </div>
    </div>
  );
};

export default InfoBanner;
